<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap pr-1 pt-7 justify-end>
          <v-flex xs2 pb-3 pl-3>
            <v-btn
              class="no-uppercase"
              dark
              width="160px"
              color="#13736f"
              @click="dialog2 = true"
              ><span
                style="
                  font-family: mainfont;
                  font-size: 15px;
                  color: white;
                  letter-spacing: 1px;
                  cursor: pointer;
                "
                >Add
              </span>
              <v-icon dark size="15px"> mdi-plus </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout
          px-2
          wrap
          class="mainfont"
          style="color: white; font-size: 16px; font-weight: lighter"
        >
          <v-flex xs12 lg3 pa-3 v-for="(item, i) in list" :key="i">
            <v-card style="border-radius: 9px">
              <v-layout wrap justify-center fill-height>
                <v-flex xs10 lg11 text-center align-self-center>
                  <v-layout wrap fill-height>
                    <v-flex
                      xs12
                      pt-4
                      style="color: #13736f; font-size: 16px; font-weight: bold"
                    >
                      {{ item.name }}
                    </v-flex>
                    <v-flex xs12 pt-3>
                      Price -
                      {{ item.price }}
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs10 lg12 pa-3 align-self-center>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs3>
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        elevation="0"
                        color="#EFFFFA"
                        @click="(editdailog = true), (curid = item)"
                      >
                        <v-icon color="black" size="130%" dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs3 pl-5>
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        elevation="0"
                        color="#EFFFFA"
                        @click="(deletedialog = true), (curid = item._id)"
                      >
                        <v-icon color="red" dark size="130%">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="dialog2"
        max-width="450px"
      >
        <v-card>
          <v-layout wrap justify-center>
            <v-flex
              ><v-layout pa-4 wrap justify-center>
                <v-flex xs12 text-right>
                  <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                    mdi-close
                  </v-icon>
                </v-flex>

                <v-flex xs10 pt-4 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                  >
                    Name</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="#13736f"
                      outlined
                      background-color="white"
                      dense
                      type="text"
                      v-model="name"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex pt-2 xs10 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >Price</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="#13736f"
                      outlined
                      background-color="white"
                      dense
                      type="number"
                      v-model="price"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs10 text-right pt-5 pb-3>
                  <v-btn dark width="100px" color="#13736f" @click="add()">
                    <span
                      class="mainfont"
                      style="color: white; font-size: 14px"
                    >
                      Add
                    </span>
                    <v-icon size="15px" color="white">mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="deletedialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title
            class="mainfont"
            style="color: black; font-size: 18px; font-weight: lighter"
            >Are you sure you want to delete this Bag Type?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="deletedialog = false"
              class="mainfont"
              >Cancel</v-btn
            >
            <v-btn color="red" class="mainfont" text @click="deleteItem()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="editdailog"
        max-width="450px"
      >
        <v-card>
          <v-layout wrap justify-center>
            <v-flex
              ><v-layout style="border-radius: 20px" pa-4 wrap justify-center>
                <v-flex xs12 text-right>
                  <v-icon
                    @click="editdailog = false"
                    color="red"
                    size="150%"
                    dark
                  >
                    mdi-close
                  </v-icon>
                </v-flex>

                <v-flex xs10 pt-4 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                  >
                    Name</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="#13736f"
                      placeholder="Name"
                      outlined
                      background-color="white"
                      dense
                      type="text"
                      v-model="curid.name"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex pt-2 xs10 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                  >
                    Price</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="#13736f"
                      placeholder="Listing Order"
                      outlined
                      background-color="white"
                      dense
                      type="number"
                      v-model="curid.price"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs10 text-right pt-4>
                  <v-btn dark width="100px" color="#13736f" @click="edit()">
                    <span
                      class="mainfont"
                      style="color: white; font-size: 14px"
                    >
                      Edit
                    </span>
                    <v-icon size="15px" color="white">mdi-pencil</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>
  
  
  <script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      preview: null,
      link: null,
      prev: null,
      page: 1,
      currentpage: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      deletedialog: false,
      price: "",
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    deleteItem() {
      axios({
        url: "/admin/bagType/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "POST",
        url: "/admin/bagType/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          price: this.price,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      axios({
        method: "POST",
        url: "/admin/bagType/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curid._id,
          name: this.curid.name,
          price: this.curid.price,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/bagType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  
  <style scoped>
.flex-red {
  background-color: #13736f;
}

.flex-g {
  background-color: #13736f;
}
.cus {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.no-uppercase {
  text-transform: none !important;
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>